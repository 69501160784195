<template>
  <div
    class="join-container"
    :class="is_mobile ? 'm-join-container' : 'pc-join-container'"
  >
    <div class="join-banner" :style="'height:' + window_height + 'px'">
      <img class="banner-bg" :src="is_mobile ? banner_m : banner" />
      <!-- <div class="banner-title">{{ $t("footer.join_us") }}</div> -->
      <div class="recuriment-type">
        <div class="recuriment-item">
          <router-link to="/join/recruitment/social">
            <div class="recu-type">
              <img class="recu-type-a" :src="recuriment_a" />
            </div>
            <div class="recuriment-type-txt">{{ $t("footer.social_recruitment") }}</div>
          </router-link>
        </div>
        <div class="recuriment-item">
          <router-link to="/join/recruitment/campus">
            <div class="recu-type">
              <img class="recu-type-b" :src="recuriment_b" />
            </div>
            <div class="recuriment-type-txt">{{ $t("footer.campus_recruitement") }}</div>
          </router-link>
        </div>
        <div class="recuriment-item">
          <router-link to="/join/recruitment/internal">
            <div class="recu-type">
              <img class="recu-type-c" :src="recuriment_c" />
            </div>
            <div class="recuriment-type-txt">
              {{ $t("footer.internal_recruitement") }}
            </div>
          </router-link>
        </div>
      </div>
    </div>
    <div class="join-content">
      <cx-join-culture
        type="live"
        :list="live"
        align="left"
        :title="'01 ' + this.$t('join_us.cx_life.label')"
      ></cx-join-culture>
      <cx-join-culture
        type="culture"
        :list="culture"
        align="right"
        :title="'02 ' + this.$t('join_us.cx_culture.label')"
      ></cx-join-culture>
      <cx-join-culture
        type="care"
        :list="care"
        align="left"
        :title="'03 ' + this.$t('join_us.cx_care.label')"
      ></cx-join-culture>
    </div>
  </div>
</template>
<script>
import JoinCultrue from "../components/JoinCulture.vue";
export default {
  name: "Join",
  props: {},
  data() {
    return {
      window_height: document.documentElement.clientHeight,

      banner: require("@/assets/join/banner.png"),
      banner_m: require("@/assets/join/banner-m.png"),
      recuriment_a: require("@/assets/join/recuriment-icon-a.png"),
      recuriment_b: require("@/assets/join/recuriment-icon-b.png"),
      recuriment_c: require("@/assets/join/recuriment-icon-c.png"),

      live: [
        {
          pc: require("@/assets/join/live-1.png"),
          m: require("@/assets/join/live-1-m.png"),
        },
        {
          pc: require("@/assets/join/live-2.png"),
          m: require("@/assets/join/live-2-m.png"),
        },
        {
          pc: require("@/assets/join/live-3.png"),
          m: require("@/assets/join/live-3-m.png"),
        },
        {
          pc: require("@/assets/join/live-4.png"),
          m: require("@/assets/join/live-4-m.png"),
        },
      ],

      culture: [
        {
          pc: require("@/assets/join/culture-1.png"),
          m: require("@/assets/join/culture-1-m.png"),
        },
        {
          pc: require("@/assets/join/culture-2.png"),
          m: require("@/assets/join/culture-2-m.png"),
        },
        {
          pc: require("@/assets/join/culture-3.png"),
          m: require("@/assets/join/culture-3-m.png"),
        },
        {
          pc: require("@/assets/join/culture-4.png"),
          m: require("@/assets/join/culture-4-m.png"),
        },
      ],

      care: [
        {
          pc: require("@/assets/join/care-1.png"),
          m: require("@/assets/join/care-1-m.png"),
        },
        {
          pc: require("@/assets/join/care-2.png"),
          m: require("@/assets/join/care-2-m.png"),
        },
        {
          pc: require("@/assets/join/care-3.png"),
          m: require("@/assets/join/care-3-m.png"),
        },
        {
          pc: require("@/assets/join/care-4.png"),
          m: require("@/assets/join/care-4-m.png"),
        },
        {
          pc: require("@/assets/join/care-5.png"),
          m: require("@/assets/join/care-5-m.png"),
        },
      ],
    };
  },
  methods: {},
  components: {
    "cx-join-culture": JoinCultrue,
  },
  mounted() {},
};
</script>
<style lang="less" scoped>
.join-banner {
  position: relative;
  overflow: hidden;
  height: 1080px;
}
.banner-bg {
  position: absolute;
  width: 1920px;
  height: 1080px;
  z-index: 0;
  left: 0px;
  top: 0px;
}
.recuriment-type {
  position: absolute;
  bottom: 125px;
  z-index: 11;
  width: 100%;
  display: flex;
  justify-content: center;
}
.recuriment-item {
  width: 500px;
}
.recu-type {
  height: 122px;
}
.recu-type-a {
  width: 125px;
}
.recu-type-b {
  width: 125px;
}
.recu-type-c {
  width: 140px;
}
.recuriment-type-txt {
  font-size: 41px;
  color: #fff;
  margin-top: 40px;
  text-align: center;
}
.banner-title {
  position: absolute;
  width: 100%;
  z-index: 11;
  top: 230px;
  font-weight: bold;
  font-size: 62px;
  color: #fff;
}

.m-join-container {
  .recu-type-a {
    width: 95px;
  }
  .recu-type-b {
    width: 95px;
  }
  .recu-type-c {
    width: 100px;
    margin-top: 15px;
  }
  .recuriment-type-txt {
    font-size: 26px;
    margin-top: 20px;
  }
  .join-banner {
    width: 750px;
    height: 1257px;
    .banner-bg {
      width: 750px;
      height: 1624px;
    }
  }
}
</style>
