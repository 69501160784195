<template>
  <div class="cx-culture" :class="is_mobile ? 'm-cx-culture' : 'pc-cx-culture'">
    <div class="cx-cul-imgs" :class="align == 'left' ? 'cx-left' : 'cx-right'">
      <div class="cx-swiper-box" :class="'cx-swiper-box-'+type">
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="(item, i) in list" :key="i">
            <img class="cx-slide-img" :src="is_mobile?item.m:item.pc" />
          </div>
        </div>
      </div>
    </div>
    <div class="cx-cul-labels" :class="align == 'left' ? 'cx-left' : 'cx-right'">
      <div class="cx-cul-title" :class="align == 'left' ? 'cx-left-labels' : 'cx-right-labels'">{{ title }}</div>
      <div class="cx-cul-name" v-for="(item, i) in list" :key="i" @click="slideTo(i)" :class="align == 'left' ? 'cx-left-labels' : 'cx-right-labels'">
        <div class="cx-cul-wrap" :class="activeIndex==i?'cx-act':''">
          <span v-if="type=='live'&&i==0">{{$t("join_us.cx_life.openness_office")}}</span>
          <span v-if="type=='live'&&i==1">{{$t("join_us.cx_life.inhouse_rd")}}</span>
          <span v-if="type=='live'&&i==2">{{$t("join_us.cx_life.lounge")}}</span>
          <span v-if="type=='live'&&i==3">{{$t("join_us.cx_life.reading_corner")}}</span>
          <span v-if="type=='culture'&&i==0">{{$t("join_us.cx_culture.all_heads_meeting")}}</span>
          <span v-if="type=='culture'&&i==1">{{$t("join_us.cx_culture.new_staff_training")}}</span>
          <span v-if="type=='culture'&&i==2">{{$t("join_us.cx_culture.enginner_forum")}}</span>
          <span v-if="type=='culture'&&i==3">{{$t("join_us.cx_culture.fresh_graduate")}}</span>
          <span v-if="type=='care'&&i==0">{{$t("join_us.cx_care.physical_examination")}}</span>
          <span v-if="type=='care'&&i==1">{{$t("join_us.cx_care.organization_activty")}}</span>
          <span v-if="type=='care'&&i==2">{{$t("join_us.cx_care.holiday_welfare")}}</span>
          <span v-if="type=='care'&&i==3">{{$t("join_us.cx_care.welfare_benifit")}}</span>
          <span v-if="type=='care'&&i==4">{{$t("join_us.cx_care.flexable_office_hours")}}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Swiper from "swiper";
export default {
  name: "JoinCulture",
  props: {
    type:{
      type:String
    },
    list: {
      type: Array,
    },
    align: {
      type: String,
      default: "left",
    },
    title: {
      type: String,
    },
  },
  data() {
    return {
      swiper_instance: null,
      activeIndex:0,
      time:new Date().getTime(),
    };
  },
  methods: {
    slideTo(index){
      this.swiper_instance.slideTo(index, 1000);
    }
  },
  components: {},
  mounted() {
    let that=this;
    this.$nextTick(()=>{
      that.swiper_instance = new Swiper(".cx-swiper-box-"+that.type, {
        allowTouchMove:false,
        direction: "vertical",
        speed: 500,
        on: {
          slideChangeTransitionStart: function(){
            that.activeIndex=this.activeIndex//切换结束时，告诉我现在是第几个slide
          },
        },
      });
    })
  },
};
</script>
<style lang="less" scoped>
.cx-culture {
  position: relative;
  width: 1450px;
  margin: 160px auto 0px;
  overflow: hidden;
}
.cx-cul-imgs {
  width: 850px;
  height: 484px;
  overflow: hidden;
}
.cx-swiper-box {
  position: relative;
  width: 850px;
  height: 484px;
}
.cx-slide-img {
  width: 850px;
  height: 484px;
}
.cx-cul-labels {
  width: 480px;
  height: 484px;
}
.cx-left {
  float: left;
}
.cx-right {
  float: right;
}
.cx-cul-title {
  width: 500px;
  text-align: left;
  font-size: 41px;
  line-height: 47px;
  font-weight: bold;
  color: transparent;
  background: linear-gradient(90deg, #ffffff 0%, #9eeaff 100%);
  -webkit-background-clip: text;
  margin-top:60px;
}
.cx-cul-name{
  position: relative;
  font-size: 25px;
  color:#fff;
  text-align: left;
  margin-top:35px;
  cursor: pointer;
}
.cx-left-labels{
  margin-left:120px;
}

.cx-right-labels{
  margin-right:120px;
  float: right;
  text-align: right;
}
.cx-cul-wrap{
  width:500px;
  user-select: none;
}
.cx-act{
  font-size: 29px;
  font-weight: bold;
}
.m-cx-culture{
  width:690px;
  height:692px;
  margin-top:40px;
  .cx-cul-imgs{
    float: none;
    width:690px;
    height:692px;
    .cx-swiper-box{
      width:690px;
      height:692px;
      .cx-slide-img{
        width:690px;
        height:692px;
      }
    }
  }
  .cx-cul-labels{
    position: absolute;
    width: 100%;
    height: 100%;
    top:0px;
    left:0px;
    z-index: 111;
    .cx-left-labels{
      margin:0px;
      text-align: center;
      float: none;
    }
    .cx-right-labels{
      margin:0px;
      float: none;
      text-align: center;
    }
    .cx-cul-title{
      margin-top:47px;
      width: auto;
    }
    .cx-cul-wrap{
      width: auto;
    }
    .cx-cul-name{
      margin-top:35px;
    }
  }
  
}
</style>
